<template>
    <Table :data="dataTable.items ? dataTable : getOrders()" />
</template>

<script>
import {  filter } from "@/main.js";
export default {
  components: {
    Table: () => import("@/components/data-table/Table"),
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dataTable: {
      title: "Pedidos",
      new: "orders-new",
      edit: "",
      // context: "orders-context",
      filter: "orders-filter",
      // massAction: "orders-mass-action",
      headerButtons: {
        filter: false,
        new: false
      },
      items: [],
      isLoading: false,
      headers: [
        { text: "Pedido", value: "order_number" },
        { text: "Orçamento", value: "budget_number" },
        { text: "Status", value: "order_status", align: "center" },
        { text: "Empresa", value: "company" },
        { text: "Total (R$)", value: "total_cost", align: 'end' },
        { text: "Data de Registro", value: "approved_registration_date", width: 200, align: 'end' },
        { text: "Data de Aprovação", value: "approved_order_date", width: 200, align: 'end' },
        { text: "Data de Entrega", value: "delivey_date", width: 200, align: 'end' },
      ],
    },
  }),
  methods: {
    getOrders() {
      this.$api
      .post("order/client",{client_id:this.client.id})
      .then((res) => {
        this.dataTable.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataTable.isLoading = false;
        });
    },
    getFilteredOrders(obj) {
      obj['client_id'] = this.client.id
      this.$api
        .post("order/filter", obj)
        .then((res) => {
          this.dataTable.items = res.data.data;
        })
        .catch(() => {
          this.dataTable.items = [];
        })
        .finally(() => {
          this.dataTable.isLoading = false;
        });
    },
  },
  created: function () {
    this.getOrders();

    filter.$once("action", (obj) => {
      if (obj) this.getFilteredOrders(obj);
      else this.getOrders();
    });
  },
};
</script>